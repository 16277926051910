<template>
    <div class="bg-[#23242A] text-white px-8 pb-48 h-screen overflow-y-auto">
        <div class="p-3 flex content-main gap-5">
            <div class="custom-full">
                <template v-if="vg_selectedLocation.id">
                    <Nav />
                    <div class="bg-[#2B2C32] rounded-lg px-8 mt-1 pt-1 pb-5">
                        <Form @submit="submit" :validation-schema="schema">
                            <div class="flex flex-row flex-wrap py-2">
                                <div class="w-full flex flex-row justify-between">
                                    <div>
                                        <span class="float-left mr-2">Delivery Hours</span>
                                    </div>
                                    <div>
                                        <button class="bg-white text-secondary uppercase rounded mx-2 px-2 py-1 hover:bg-gray-300" >Save</button>
                                    </div>
                                </div>

                                <label class="w-full block mt-2">
                                    <Field  v-slot="{field, errors, errorMessage}" name="deliveryNotice" v-model="form.deliveryNotice" >
                                        <div :class="labelClass(field.value, errorMessage)" class="text-xs">Delivery Notice</div>
                                        <textarea class="block w-full rounded bg-gray-black text-sm" :class="inputClass(field.value, errorMessage)" v-bind="field" placeholder="Whatever helps your customers place an order..."></textarea>
                                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                    </Field>
                                </label>

                                <label class="w-full block mt-2 flex flex-row align-middle gap-2">
                                    <div class="text-sm pt-2">Set to same as Store Hours</div>
                                    <Field v-slot="{field}" name="deliverySameAsStoreHours" class="flex md:flex-row flex-col" type="checkbox" v-model="form.deliverySameAsStoreHours" :value="true" :unchecked-value="false">
                                        <input type="checkbox" class="sr-only peer" :value="true" v-bind="field">
                                        <div class="float-left mt-2 relative toggle-bg bg-theme-red peer-checked:bg-theme-green peer-checked:border-theme-green h-5 w-10 rounded-full"></div>
                                        <span :class="form.deliverySameAsStoreHours?'text-theme-green':'text-theme-red'" class="inline-block text-sm align-top mt-2 ml-2">{{form.deliverySameAsStoreHours?'ON':'OFF'}}</span>
                                    </Field>
                                </label>

                                <div class="w-full mt-4 mb-2 text-sm">Delivery Hours</div>
                                <div class="w-[400px] h-8 bg-theme-orange/[0.2] rounded flex p-2 gap-2">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.62109 11.9336C7.83359 11.9336 8.01172 11.8617 8.15547 11.718C8.29922 11.5742 8.37109 11.3961 8.37109 11.1836C8.37109 10.9711 8.29922 10.793 8.15547 10.6492C8.01172 10.5055 7.83359 10.4336 7.62109 10.4336C7.40859 10.4336 7.23047 10.5055 7.08672 10.6492C6.94297 10.793 6.87109 10.9711 6.87109 11.1836C6.87109 11.3961 6.94297 11.5742 7.08672 11.718C7.23047 11.8617 7.40859 11.9336 7.62109 11.9336ZM7.62109 8.93359C7.83359 8.93359 8.01172 8.86172 8.15547 8.71797C8.29922 8.57422 8.37109 8.39609 8.37109 8.18359V5.18359C8.37109 4.97109 8.29922 4.79297 8.15547 4.64922C8.01172 4.50547 7.83359 4.43359 7.62109 4.43359C7.40859 4.43359 7.23047 4.50547 7.08672 4.64922C6.94297 4.79297 6.87109 4.97109 6.87109 5.18359V8.18359C6.87109 8.39609 6.94297 8.57422 7.08672 8.71797C7.23047 8.86172 7.40859 8.93359 7.62109 8.93359ZM7.62109 15.6836C6.58359 15.6836 5.60859 15.4867 4.69609 15.093C3.78359 14.6992 2.98984 14.1648 2.31484 13.4898C1.63984 12.8148 1.10547 12.0211 0.711719 11.1086C0.317969 10.1961 0.121094 9.22109 0.121094 8.18359C0.121094 7.14609 0.317969 6.17109 0.711719 5.25859C1.10547 4.34609 1.63984 3.55234 2.31484 2.87734C2.98984 2.20234 3.78359 1.66797 4.69609 1.27422C5.60859 0.880469 6.58359 0.683594 7.62109 0.683594C8.65859 0.683594 9.63359 0.880469 10.5461 1.27422C11.4586 1.66797 12.2523 2.20234 12.9273 2.87734C13.6023 3.55234 14.1367 4.34609 14.5305 5.25859C14.9242 6.17109 15.1211 7.14609 15.1211 8.18359C15.1211 9.22109 14.9242 10.1961 14.5305 11.1086C14.1367 12.0211 13.6023 12.8148 12.9273 13.4898C12.2523 14.1648 11.4586 14.6992 10.5461 15.093C9.63359 15.4867 8.65859 15.6836 7.62109 15.6836Z" fill="#E7A644"/>
                                    </svg>
                                    <span class="text-xs">Your delivery hours must abide by the laws in your jurisdiction.</span>
                                </div>
                                <div class="w-full text-xs mt-2">All Days</div>
                                <label class="font-semibold text-theme-878686 block mb-5 cursor-pointer">
                                    <Field v-slot="{field}" name="allDay" class="flex md:flex-row flex-col" type="checkbox" v-model="allDay" :value="true" :unchecked-value="false">
                                        <input type="checkbox" class="sr-only peer" :value="true" v-bind="field" @change="toggleAllDay">
                                        <div class="float-left mt-2 relative toggle-bg bg-theme-red peer-checked:bg-theme-green peer-checked:border-theme-green h-5 w-10 rounded-full"></div>
                                        <span :class="allDay?'text-theme-green':'text-theme-red'" class="inline-block text-sm align-top mt-2 ml-2">{{allDay?'ON':'OFF'}}</span>
                                    </Field>
                                </label>

                                <!-- days -->
                                <div class="flex large:flex-row flex-col  w-full mt-2" v-for="(day, ind) in deliveryHours" :key="ind">
                                    <div class="admin-table mt-7 w-36 large:mb-0 mb-3">
                                        <label>
                                            <Field  v-slot="{field}" :name="day.dayName" type="checkbox" v-model="deliveryHours[ind].isOpen" :value="true" :unchecked-value="false">
                                                <input :disabled="form.deliverySameAsStoreHours" type="checkbox" class="vtl-tbody-checkbox" :value="true" v-bind="field">
                                                <span class="ml-3">{{day.dayName}}</span>
                                            </Field>
                                        </label>
                                    </div>
                                    <div class="flex large:flex-row flex-col large:space-x-2 w-full">
                                        <div class="large:mb-0 mb-3">
                                            <div>Start Time: </div>
                                            <div class="block rounded  w-full relative">
                                                <div class="bg-gray-black rounded flex items-center w-full pr-[80px] px-1 py-2">
                                                    <select :disabled="form.deliverySameAsStoreHours" v-model="deliveryHours[ind].openTime.HH" class="bg-gray-black border-0 focus:border-transparent focus:ring-0 text-sm w-[50px] pl-2 py-0 pr-0 sm-arrow">
                                                        <option :value="hour > 9 ? hour : '0'+hour" v-bind:key="hour + 'hour'" v-for="hour in 12">{{ hour > 9 ? hour : '0'+hour }}</option>
                                                    </select>
                                                    <span>:</span>
                                                    <select :disabled="form.deliverySameAsStoreHours" v-model="deliveryHours[ind].openTime.MM" class="bg-gray-black border-0 focus:border-transparent focus:ring-0 text-sm w-[50px] pl-2 py-0 pr-0 sm-arrow">
                                                        <option :value="min > 9 ? min : '0'+min" v-bind:key="min + 'min'" v-for="(key, min ) in 60">{{ min > 9 ? min : '0'+min }}</option>
                                                    </select>
                                                </div>
                                                <label class="flex items-center cursor-pointer absolute right-1 top-2">
                                                    <input :disabled="form.deliverySameAsStoreHours" type="checkbox" v-model="deliveryHours[ind].openTime.AM" class="sr-only peer">
                                                    <div :class="'relative toggle-bg ampm-inside-switch border-2 h-6 w-[50px] rounded-full ' + (deliveryHours[ind].openTime.AM?'left ':'right ') + (deliveryHours[ind].openTime.AM ? 'bg-theme-darkgreen border-theme-green' : 'bg-theme-darkgreen  border-theme-green')">
                                                        <span :class="'text-white text-xs leading-loose ' + (deliveryHours[ind].openTime.AM ? 'left' : 'right')">{{deliveryHours[ind].openTime.AM ? 'AM' : 'PM'}}</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="large:mb-0 mb-3">
                                            <div :class="errors[ind]?'text-theme-red':''">End Time: </div>
                                            <div class="block rounded bg-gray-black w-full relative" :class="errors[ind]?'border border-theme-red':''">
                                                <div class="bg-gray-black rounded flex items-center w-full pr-[80px] rounded px-1 py-2">
                                                    <select :disabled="form.deliverySameAsStoreHours"  v-model="deliveryHours[ind].closeTime.HH" class="bg-gray-black border-0 focus:border-transparent focus:ring-0 text-sm w-[50px] pl-2 py-0 pr-0 sm-arrow">
                                                        <option :value="hour > 9 ? hour : '0'+hour" v-bind:key="hour + 'hour'" v-for="hour in 12">{{ hour > 9 ? hour : '0'+hour }}</option>
                                                    </select>
                                                    <span>:</span>
                                                    <select :disabled="form.deliverySameAsStoreHours" v-model="deliveryHours[ind].closeTime.MM" class="bg-gray-black border-0 focus:border-transparent focus:ring-0 text-sm w-[50px] pl-2 py-0 pr-0 sm-arrow">
                                                        <option :value="min > 9 ? min : '0'+min" v-bind:key="min + 'min'" v-for="(key, min ) in 60">{{ min > 9 ? min : '0'+min }}</option>
                                                    </select>
                                                </div>
                                                <label class="flex items-center cursor-pointer absolute right-1 top-2">
                                                    <input :disabled="form.deliverySameAsStoreHours" type="checkbox" v-model="deliveryHours[ind].closeTime.AM" class="sr-only peer">
                                                    <div :class="'relative toggle-bg ampm-inside-switch border-2 h-6 w-[50px] rounded-full ' + (deliveryHours[ind].closeTime.AM?'left ':'right ') + (deliveryHours[ind].closeTime.AM ? 'bg-theme-darkgreen border-theme-green' : 'bg-theme-darkgreen  border-theme-green')">
                                                        <span :class="'text-white text-xs leading-loose ' + (deliveryHours[ind].closeTime.AM ? 'left' : 'right')">{{deliveryHours[ind].closeTime.AM ? 'AM' : 'PM'}}</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="text-theme-red mt-8" v-if="errors[ind]">
                                            <span class="">{{errors[ind]}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    </div>
                </template>
                <div v-else class="bg-[#2B2C32] rounded-lg px-8 mt-1 pt-1 pb-5">
                    <p class="text-center text-theme-red mt-3">Please select a location from the top left corner.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from './Nav'
import pick from 'lodash/pick'
import moment from 'moment'
import { defineComponent, ref } from 'vue'
import { Form, Field } from 'vee-validate'
import { useSnackbar } from "vue3-snackbar";
import { mapGetters, mapMutations } from "vuex";
import ObjectHelper from '@/helpers/object'
import Helpers from '@/helpers';

import LocationDeliverySettingApi from "@/controllers/LocationDeliverySetting";
import LocationOperationHoursApi from "@/controllers/LocationOperationHoursApis"

export default defineComponent({
    components: {
        Nav,
        Form,
        Field
    },
    setup() {
        const snackbar = useSnackbar();
        const form = ref({
            deliveryActive: false,
            deliveryNotice: '',
            deliverySameAsStoreHours: false,
            deliveryTimeSlots: false,
            timeSlotDuration: 10,
            timeSlotCutoff: 10,
            timeSlotPeriod: 1,
            orderLimitPerTimeSlot: 0,
            cancellationCutoff: 0,
            payOnline: false,
            payAtDoorCc: false,
            payAtDoorCash: false

        })
        const storePayload = ref([])
        const newRecord = ref(false)
        const allDay = ref(false)
        const storeHours = ref('')
        const storeDeliveryHours = ref('')
        const deliveryHours = ref([])
        const isDeliverySameAsStoreHour = ref(null)
        const schema = {
            deliveryNotice: '',
            timeSlotDuration: '',
            timeSlotCutoff: '',
            // orderLimitPerTimeSlot: 'required|positiveInteger',
            // cancellationCutoff: 'required|positiveInteger',
            // timeSlotPeriod: 'required|positiveInteger'

        }
        const dayTemplate = {
            day: 0,
            dayName: 'Sunday',
            isOpen: true,
            openTime: {
                HH: '01',
                MM: '00',
                AM: true
            },
            closeTime: {
                HH: '01',
                MM: '01',
                AM: true
            },
            cutoffTime: null
        }
        const errors = []
        const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        return {snackbar, form, storePayload, newRecord, storeHours, allDay, storeDeliveryHours, deliveryHours, schema, dayTemplate, dayName, errors, isDeliverySameAsStoreHour}
    },
    async mounted(){
        this.vm_spinner(true)
        await this.getData()
        await this.getStoreHours()
        await this.getDeliveryHour()
        this.getCurrentHours()
        this.updateDeliveryHourToDeliverySameAsStoreHour(this.isDeliverySameAsStoreHour)
        this.vm_spinner(false)
    },
    watch: {
        vg_selectedLocation(){
            this.init()
        },
        deliveryHours:{
            handler:function(){
                this.validateDeliveryHours()
            },
            deep:true
        },
        'form.deliverySameAsStoreHours'(val){
            this.updateDeliveryHourToDeliverySameAsStoreHour(val)
        },
        isDeliverySameAsStoreHour(val){
            this.updateDeliveryHourToDeliverySameAsStoreHour(val)
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        async init(){
            await this.getData()
            await this.getDeliveryHour()
            this.getStoreHours()
            this.getCurrentHours()
        },
        updateDeliveryHourToDeliverySameAsStoreHour(val){
            if(val){
                this.deliveryHours = this.storeHours?JSON.parse(this.storeHours):[]
            }else{
                this.deliveryHours = this.storeDeliveryHours?JSON.parse(this.storeDeliveryHours):[]
            }
            this.allDay = (this.deliveryHours.every(each => each.isOpen===true))?true:false

        },
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        toggleAllDay(val){
            if(val){
                for(let i=0; i<this.deliveryHours.length; i++){
                    this.deliveryHours[i].isOpen = true
                }
            }else{
                let deliveryArr = []
                if(this.form.deliverySameAsStoreHours){
                    deliveryArr = this.storeHours?JSON.parse(this.storeHours):[]
                }else{
                    deliveryArr = this.storeDeliveryHours?JSON.parse(this.storeDeliveryHours):[]
                }
                if(deliveryArr?.length>0){
                    for(let i=0; i<deliveryArr.length; i++){
                        this.deliveryHours[i].isOpen = deliveryArr[i].isOpen
                    }
                }
            }
        },
        async getData(){
            let result = await LocationDeliverySettingApi.fetchLocationDeliverySetting()
            if(result.status==200){
                this.form = pick(result.data.payload, ['deliveryActive', 'deliveryNotice', 'deliverySameAsStoreHours', 'deliveryTimeSlots', 'timeSlotDuration', 'timeSlotPeriod', 'timeSlotCutoff', 'orderLimitPerTimeSlot', 'cancellationCutoff', 'payOnline', 'payAtDoorCc', 'payAtDoorCash'])
                this.newRecord = false
                this.isDeliverySameAsStoreHour = result.data.payload.deliverySameAsStoreHours
            }else{
                this.form = {
                    deliveryActive: false,
                    deliveryNotice: '',
                    deliveryTimeSlots: false,
                    timeSlotDuration: '',
                    timeSlotCutoff: '',
                    orderLimitPerTimeSlot: 0,
                    cancellationCutoff: 0,
                    payOnline: false,
                    payAtDoorCc: false,
                    payAtDoorCash: false
                }
                this.newRecord = true
                this.isDeliverySameAsStoreHour = false
            }
        },
        async getDeliveryHour(){
            let storeDeliveryHours = []
            let res = await LocationDeliverySettingApi.fetchLocationDeliveryHour()
            if(res.data && res.data.payload && res.data.payload.length>0){
                for(let i=0; i<res.data.payload.length; i++){
                    let obj = pick(res.data.payload[i], ['day', 'dayName', 'isOpen', 'cutoffTime'])
                    let openTime = this.convertTimeToTimeObject(res.data.payload[i].openTime)
                    let closeTime = this.convertTimeToTimeObject(res.data.payload[i].closeTime)
                    obj.closeTime = closeTime
                    obj.openTime = openTime
                    storeDeliveryHours.push({...obj})
                }
            }else{
                for(let i=0; i<7; i++){
                    this.dayTemplate.day = i;
                    this.dayTemplate.dayName = this.dayName[i]
                    this.dayTemplate.isOpen = false
                    storeDeliveryHours.push(JSON.parse(JSON.stringify(this.dayTemplate)))
                }
            }
            this.storeDeliveryHours = JSON.stringify(storeDeliveryHours)
        },
        async getStoreHours(){
            let storeHoursArr = []
            let res = await LocationOperationHoursApi.fetchLocationOperationHours()
            this.storePayload = res
            if(res.data && res.data.payload && res.data.payload.length>0){
                for(let i=0; i<res.data.payload.length; i++){
                    let obj = pick(res.data.payload[i], ['day', 'dayName', 'isOpen', 'cutoffTime'])
                    let openTime = this.convertTimeToTimeObject(res.data.payload[i].openTime)
                    let closeTime = this.convertTimeToTimeObject(res.data.payload[i].closeTime)
                    obj.closeTime = closeTime
                    obj.openTime = openTime
                    storeHoursArr.push(obj)
                }
            }else{
                for(let i=0; i<7; i++){
                    this.dayTemplate.day = i;
                    this.dayTemplate.dayName = this.dayName[i]
                    storeHoursArr.push(JSON.parse(JSON.stringify(this.dayTemplate)))
                }
            }
            this.storeHours = JSON.stringify(storeHoursArr)
        },
        async getCurrentHours(){
            this.deliveryHours = []
            this.allDay = (this.deliveryHours.every(each => each.isOpen===true))?true:false
            if(this.form.deliverySameAsStoreHours){
                let hours = JSON.parse(this.storeHours)
                this.deliveryHours = hours
            }else{
                let hours = JSON.parse(this.storeDeliveryHours)
                this.deliveryHours = hours
            }
        },
        isDeliveryHourValid(){
            return this.errors.every(each => each===null)
        },
        validateDeliveryHours(){
            let openTime = null
            let closeTime = null
            for(let i=0; i<this.deliveryHours.length; i++){
                openTime = moment(this.covertTimeObjectToTime(this.deliveryHours[i].openTime), 'HH:mm:ss')
                closeTime = moment(this.covertTimeObjectToTime(this.deliveryHours[i].closeTime), 'HH:mm:ss')
                if(openTime.isBefore(closeTime)){
                    this.errors[i] = null
                }else{
                    this.errors[i] = 'End time must be greater than start time'
                }
            }
            this.allDay = (this.deliveryHours.every(each => each.isOpen===true))?true:false
        },
        async submit(form, {resetForm}){
            if(this.isDeliveryHourValid()){
                this.vm_spinner(true)
                let res = null
                let res2 = null
                let deliveryData = null
                if(!this.form.deliverySameAsStoreHours){
                    if(this.deliveryHours?.every(each => !each.isOpen)){
                        this.form.deliveryActive =false
                        res = await LocationDeliverySettingApi.removeLocationDeliveryHour()
                    }else{
                        deliveryData = {
                            locationId: this.vg_selectedLocation.id, 
                            deliveryHours: await this.getToPostDeliveryHour()
                        }
                        res = await LocationDeliverySettingApi.createLocationDeliveryHour(deliveryData)
                    }
                }
                if(this.newRecord){
                    res2 = await LocationDeliverySettingApi.createLocationDeliverySetting(ObjectHelper.emptyStringToNull(this.form))
                }else{
                    res2 = await LocationDeliverySettingApi.updateLocationDeliverySetting(ObjectHelper.emptyStringToNull(this.form))
                }

                if(this.form.deliverySameAsStoreHours){
                    this.snackbar.add({
                        type: 'info',
                        text: (res2.status==200 || res2.status==201)?'Location delivery hours added!' : Helpers.catchErrorMessage(res2.response.data),
                        duration: 3000,
                    })
                }else{
                    this.snackbar.add({
                        type: 'info',
                        text: (res.status==200 || res.status==201)?res.data.message : Helpers.catchErrorMessage(res.response.data),
                        duration: 3000,
                    })
                }
                this.init()
                this.vm_spinner(false)
            }
        },
        async getToPostDeliveryHour(){
            let deliveryHourArr = []
            for(let i=0; i<this.deliveryHours.length; i++){
                let obj = pick(this.deliveryHours[i], ['day', 'dayName', 'isOpen', 'cutoffTime'])
                let openTime = this.covertTimeObjectToTime(this.deliveryHours[i].openTime)
                let closeTime = this.covertTimeObjectToTime(this.deliveryHours[i].closeTime)
                obj.closeTime = closeTime
                obj.cutoffTime = closeTime
                obj.openTime = openTime
                deliveryHourArr.push(obj)
            }
            return deliveryHourArr
        },
        covertTimeObjectToTime(obj){
            let time = obj.HH+':'+obj.MM+' '+(obj.AM?'AM':'PM')
            return moment((moment().format('YYYY-MM-DD')+' '+time), 'YYYY-MM-DD h:mm A').format('HH:mm:ss')
        },
        convertTimeToTimeObject(time){
            return {
                HH: moment((moment().format('YYYY-MM-DD')+' '+time)).format('hh'),
                MM: moment((moment().format('YYYY-MM-DD')+' '+time)).format('mm'),
                AM: (moment((moment().format('YYYY-MM-DD')+' '+time)).format('A')=='AM')?true:false
            }
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
<style scoped>
.ampm-inside-switch .left{
    position: relative;
    top: -3px; 
    left: 6px; 
    font-size: 12px;
}
.ampm-inside-switch .right{
    position: relative;
    top: -3px; 
    left: 23px; 
    font-size: 12px;
}
.ampm-inside-switch.left:after{
    left: 5px !important;
}
</style>


<template>
  <aside id="leftNav"  class="fixed z-[99] left-0 top-[60px] bottom-0 sidebar bg-theme-grey ease-linear duration-300" :class="vg_leftNav?'min-w-[240px] w-[240px]':'w-[68px]'">

    <div class="sidebar-content pb-48 relative h-[calc(100vh)] overflow-y-scroll">
      <accordion :availableSidebarRoutes="availableSidebarRoutes" :default="0" />
    </div>

    <div class="fixed bottom-0 left-0 bg-theme-grey py-3 mx-auto border-t border-theme-modalbg" :class="vg_leftNav?'w-[240px]':'w-[68px]'">
      <span class="block text-center text-white">© Tendy {{new Date().getFullYear()}}</span>
      <span class="block text-center text-grey mt-1 text-xs">Version 0.8.2</span>
    </div>

  </aside>
</template>

<script>
import {useRouter} from 'vue-router';
import {defineComponent, ref} from "vue"
import { LocationMarkerIcon } from '@heroicons/vue/outline'
import LocationSelector from '@/components/form/LocationSelector'
import Accordion from '@/components/form/Accordion'
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
const router = useRouter();

  export default defineComponent({
  name: "LeftNav",
  components: {
    LocationSelector,
    Accordion,
    LocationMarkerIcon,
  },

  setup() {
      const availableSidebarRoutes = ref([]);
    return {
      availableSidebarRoutes,
    };
  },

  computed: {
    ...mapGetters({
      vg_leftNav: "nav/leftNav",
      vg_userType: 'auth/userType',
      vg_locations: "location/list",
      vg_userPermisiions: "auth/userPermissions"
    }),
  },

  watch: {
    "vg_userPermisiions"() {
      this.initMenu()
    },
  },
 

  mounted() {
    this.initMenu()
  },

  methods: {
    /**
     * Create menu
     */
    initMenu() {
      // sync location to store
      this.va_setAllLocations();
      // while checking route check for  permission if not available make display false
      let allAvailableRoute = this.$router.options.routes.filter((item) => {
        
        //  if hardcoded display Parent
        if(item.meta.permissions && item.meta.permissions.length > 0) {
          item.meta.permissions.forEach(permission => {
            if(Helpers.getMyPermission(permission) == false) {
              item.meta.display = false;
            }
          })
        }

        if (item.meta && item.meta.display) {

        
          // We need to check for child items also
          if(item.children && item.children.length > 0) {

            
            let children = item.children.filter(child => {
              if(child.meta.permissions && child.meta.permissions.length > 0) {
                child.meta.permissions.forEach(permission => {
                  if(Helpers.getMyPermission(permission) == false) {
                    child.meta.display = false;
                  }
                })
              }

              // check if display rule exist
              if(child.meta.display){
                if(child.meta.displayUser && child.meta.displayUser[0]){
                  if(this.vg_userType==child.meta.displayUser[0]){
                    child.meta.display = true
                  }else{
                    child.meta.display = false
                  }
                }
              }
              
              let data;
              data = child;
              if(data.meta.display) {

                
                return child;
              }
            });
            item.children = children;
          }
          return item;
        }
      });



      this.availableSidebarRoutes = allAvailableRoute;
    },

    /**
     * toggle side nav
     */
    toggleLeftNav() {
      this.va_setLeftNav(!this.leftNav)
    },

    openLeftNav(){
      this.va_setLeftNav(true)
    },
    
    /**
     * Logout
     */
    logout() {
      this.va_clearUserData();
      this.$router.push({
        name: 'Login',
        query: {
          reload: 1
        }
      })
    },

    async selectTheLocation(location) {
      this.va_spinner(true);
      await this.va_selectTheLocation(location);
      this.va_spinner(false);
    },

    ...mapActions({
      va_clearUserData: 'auth/clearUserData',
      va_setLeftNav: 'nav/setLeftNav',
      va_spinner: 'spinner/setSpinner',
      va_setAllLocations: 'location/setAllLocations',
      va_selectTheLocation: 'location/selectTheLocation',
    })

  },
});

</script>

<template>
<div class="relative flex flex-col" tabindex="0" @blur="close" >
    <div class="flex flex-row justify-between h-9 py-2 px-3 cursor-pointer" @click="toggleOpen">
        <div class="flex flex-row gap-2">
            <slot name="placeholderIcon" />
            <div :class="(selected && selected.length>0)?'text-white':'text-grey'">{{(selected && selected.length>0)?(selected.length+' selected'):placeholder}}</div>
        </div>
        <div class="mt-2">
            <svg v-if="open" width="11" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83008 0.624219L0.830078 6.62422L1.90508 7.69922L6.83008 2.74922L11.7551 7.67422L12.8301 6.59922L6.83008 0.624219Z" fill="#878686"/>
            </svg>
            <svg v-else width="11" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83325 7.37578L0.833252 1.37578L1.90825 0.300781L6.83325 5.25078L11.7583 0.325781L12.8333 1.40078L6.83325 7.37578Z" fill="#878686"/>
            </svg>
        </div>
    </div>
    <div v-if="open" class="flex flex-col absolute top-[40px] w-[200px] max-h-[300px] overflow-auto z-[50]">
        <div @click="select(option.id)" class="relative grid grid-cols-6 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" v-for="option in options" :key="'brd-'+option.id" :value="option.id">
            <div class="mr-2 self-center">
                <svg v-if="isAdded(option.id)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.45 17.55C0.75 17.85 1.1 18 1.5 18H16.5C16.9 18 17.25 17.85 17.55 17.55C17.85 17.25 18 16.9 18 16.5V1.5C18 1.1 17.85 0.75 17.55 0.45C17.25 0.15 16.9 0 16.5 0H1.5C1.1 0 0.75 0.15 0.45 0.45C0.15 0.75 0 1.1 0 1.5V16.5C0 16.9 0.15 17.25 0.45 17.55ZM16.5 16.5H1.5V1.5H16.5V16.5Z" fill="#2BA66B"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 16.5H16.5V1.5H1.5V16.5ZM14.7 5.75L7.475 12.975L3.425 8.925L4.5 7.85L7.475 10.825L13.625 4.675L14.7 5.75Z" fill="#2BA66B"/>
                    <path d="M7.475 12.975L14.7 5.75L13.625 4.675L7.475 10.825L4.5 7.85L3.425 8.925L7.475 12.975Z" fill="white"/>
                </svg>
                <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 18C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H16.5C16.9 0 17.25 0.15 17.55 0.45C17.85 0.75 18 1.1 18 1.5V16.5C18 16.9 17.85 17.25 17.55 17.55C17.25 17.85 16.9 18 16.5 18H1.5ZM1.5 16.5H16.5V1.5H1.5V16.5Z" fill="white"/>
                </svg>
            </div>
            <div class="col-span-5 break-words inline-block">{{option.name}}</div>
        </div>
    </div>
</div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent({
    props: {
        modelValue: String,
        placeholder:String,
        options: Array
    },
    data(){
        return {
            open: false,
            selected: []
        }
    },
    mounted(){
        if(this.modelValue && this.modelValue.length>0){
            this.selected = this.modelValue
        }else{
            this.selected = []
        }
    },
    watch: {
        modelValue(){
            if(this.modelValue && this.modelValue.length>0){
                this.selected = this.modelValue
            }else{
                this.selected = []
            }
        }
    },
    methods: {
        toggleOpen(){
            this.open = !this.open
        },
        close(){
            this.open = false
        },
        isAdded(id){
            if(this.selected){
                return this.selected.includes(id)
            }
        },
        select(id){
            if(this.selected && this.selected.length>0){
                const ind = this.selected.indexOf(id)
                if(ind>-1){
                    this.$emit('changed', 'removed', id)
                    this.selected.splice(ind, 1)
                }else{
                    this.$emit('changed', 'added', id)
                    this.selected.push(id)
                }
            }else{
                this.$emit('changed', 'added', id)
                this.selected.push(id)
            }
            this.$emit('update:modelValue', this.selected)
        }
    }
})
</script>
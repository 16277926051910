<template>
    <div class="bg-[#23242A] text-white h-screen appheader overflow-y-auto p-6">
        <div class="grid grid-cols-1">
          <div v-if="vg_locationSelected.id">
                <block-loading v-if="loading" />
                <div v-else class="bg-[#2B2C32] rounded-lg px-8 mt-1 pb-3">
                    <div class=" flex justify-between items-center py-2">
                        <h1 class="text-white font-semibold text-[24px]">Price Settings</h1>
                        <button  @click.prevent="addNew()" class="bg-white text-theme-green rounded  mt-2 px-4 py-2 disabled:bg-theme-grey hover:bg-slate-300"><span class=" tracking-widest font-medium">SAVE</span></button>
                    </div>
                    <div class="flex flex-col justify-start box-border text-white h-auto bg-[#2B2C32] mb-2">
                      <h3
                        class="font-bold text-base leading-5 mb-[18px] tracking-[0.15px]"
                      >
                        Select a setting:
                      </h3>
                      <div
                        class="flex items-center gap-[18px] border-b border-[#464851] pb-4"
                      >
                        <div class="flex items-center">
                          <input
                            :checked="selectedType==='margin'"
                            value="product"
                            id="byProduct"
                            @click="selectType('margin')"
                            type="radio"
                            name="selectType"
                            class="w-4 h-4 bg-transparent border-2 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="byProduct"
                            class="ml-2 text-base leading-5 font-normal tracking-wider text-white dark:text-gray-300"
                            >Margin automatic pricing</label
                          >
                        </div>
                        <div class="flex items-center">
                          <input
                            :checked="selectedType==='markup'"
                            id="byOrder"
                            value="order"
                            @click="selectType('markup')"
                            type="radio"
                            name="selectType"
                            class="w-4 h-4 bg-transparent border-2 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="byOrder"
                            class="ml-2 text-base leading-5 font-normal tracking-wider text-white dark:text-gray-300"
                            >Markup Pricing automatic pricing</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col py-2">
                      <h3 class="font-[700] text-[20px]">Markup pricing automatic pricing settings</h3>
                      <p class="font-[400] text-[16px]">
                        This setting will automatically suggest a price for any new products added to your catalogue.</p>
                    </div>
                    <div class="flex gap-3 flex-col items-start my-3">
                        <div class="admin-table w-full flex flex-row gap-2 items-center">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="driedflower" type="checkbox" value="1" class="mr-3" v-model="form.driedflower.status"/>
                                <label for="driedflower" class="text-sm mb-1 font-normal">Dried flower</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('driedflower')" v-model="form.driedflower.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="prerolled" type="checkbox" value="1" class="mr-3" v-model="form.prerolled.status"/>
                                <label for="prerolled" class="text-sm mb-1 font-normal">Pre rolled</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('prerolled')" v-model="form.prerolled.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="extractsingested" type="checkbox" value="1" class="mr-3" v-model="form.extractsingested.status"/>
                                <label for="extractsingested" class="text-sm mb-1 font-normal">Extracts ingested</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('extractsingested')" v-model="form.extractsingested.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center xl:w-1/4">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="extractsinhaled" type="checkbox" value="1" class="mr-3" v-model="form.extractsinhaled.status"/>
                                <label for="extractsinhaled" class="text-sm mb-1 font-normal">Extracts inhaled</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('extractsinhaled')" v-model="form.extractsinhaled.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center xl:w-1/4">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="ediblessolids" type="checkbox" value="1" class="mr-3" v-model="form.ediblessolids.status"/>
                                <label for="ediblessolids" class="text-sm mb-1 font-normal">Edibles solids</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('ediblessolids')" v-model="form.ediblessolids.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center xl:w-1/4">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="ediblesnonsolids" type="checkbox" value="1" class="mr-3" v-model="form.ediblesnonsolids.status"/>
                                <label for="ediblesnonsolids" class="text-sm mb-1 font-normal">Edibles non-solids</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('ediblesnonsolids')" v-model="form.ediblesnonsolids.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center xl:w-1/4">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="topicals" type="checkbox" value="1" class="mr-3" v-model="form.topicals.status"/>
                                <label for="topicals" class="text-sm mb-1 font-normal">Topicals</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('topicals')" v-model="form.topicals.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                        <div class="admin-table w-full flex flex-row gap-2 items-center xl:w-1/4">
                            <label class="cursor-pointer text-sm min-w-[200px]">
                                <input id="accessory" type="checkbox" value="1" class="mr-3" v-model="form.accessory.status"/>
                                <label for="accessory" class="text-sm mb-1 font-normal">Accessory</label>
                            </label>
                            <input placeholder="ex. 60%" @keyup="formatPercentage('accessory')" v-model="form.accessory.value" type="text" class="block rounded bg-gray-black border-none w-[80px] text-sm text-theme-white">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-[#2B2C32] rounded-lg px-8 py-[16px]" v-else>
              <p  class="text-center text-theme-red">Please select a location from the top left corner.</p>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import PriceMarkupSettingApis from "@/controllers/LocationMarkupPrice";
import Helpers from '@/helpers';

export default defineComponent({
  setup() {
    const snackbar = useSnackbar();
    const loading = ref(false)
    const payload = ref({})
    const form = ref({
      driedflower: {
        status: false,
        value: '',
      },
      prerolled: {
        status: false,
        value: '',
      },
      extractsingested: {
        status: false,
        value: '',
      },
      extractsinhaled: {
        status: false,
        value: '',
      },
      ediblessolids: {
        status: false,
        value: '',
      },
      ediblesnonsolids: {
        status: false,
        value: '',
      },
      topicals: {
        status: false,
        value: '',
      },
      accessory: {
        status: false,
        value: '',
      }
    });
    const keys = ['driedflower', 'prerolled', 'extractsingested', 'extractsinhaled', 'ediblessolids', 'ediblesnonsolids', 'topicals', 'accessory']
    const update = false
    const selectedType = ref('margin')
    return {
      payload,
      form,
      keys,
      update,
      snackbar,
      loading,
      selectedType
    };
  },
  mounted() {
    if(this.vg_locationSelected?.id){
      this.fetchData()
    }
  },
  watch: {
    vg_locationSelected(){
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
        vg_locationSelected: "location/selected",
    })
  },
  methods: {
    selectType(val) {
      this.selectedType = val
    },

    formatPercentage(key){
        if(this.form[key].value){
            let number = this.form[key].value
            if(!number || number=='%') this.form[key].value = ''
            
            let x = number.replace(/\D/g, '').match(/[1-9][0-9]?$|^100/)
            this.form[key].value = (x&&x[0]) ? x[0]+'%' : null
        }
    },
    async fetchData(){
      this.loading = true
      let res = await PriceMarkupSettingApis.fetchByLocationId(this.vg_locationSelected.id)
      if(res.status==200 && res.data.payload){
        this.update = true
        let payloads = res.data.payload
        for(let i=0; i<this.keys.length; i++){
          let payloadObj = payloads.find(each => each.complianceType.toLowerCase()==this.keys[i])
          this.form[this.keys[i]].id = payloadObj.id
          this.form[this.keys[i]].value = payloadObj.markupPercentage?payloadObj.markupPercentage+'%':'0%'
          this.form[this.keys[i]].status = payloadObj.status
          this.selectedType = payloadObj.markupType
        }
      }else{
        for(let i=0; i<this.keys.length; i++){
          this.form[this.keys[i]].value = ''
          this.form[this.keys[i]].status = false
        }
      }
      this.loading = false
    },

    getFormattedData(form){
        let data = []
        for(let key in form){
            data.push({
                locationId: this.vg_locationSelected.id, 
                complianceType: key, 
                markupPercentage: this.form[key].value?parseFloat(this.form[key].value.replace('%', '')):0, 
                status: this.form[key].status,
                markupType: this.selectedType
            })
        }
        return {markupPrices: data}
    },
    isValid(){
      let valid = true
      for(let key in this.form){
        if(!this.form[key].value || this.form[key].value && !this.form[key].value.replace('%', '')){
          valid = false
        }
      }
      return valid
    },

    /**
     * Add 
     */
    async addNew() {
      let res
      this.va_spinner(true);
      let formData = this.getFormattedData(this.form)
      if(this.update){
        res = await PriceMarkupSettingApis.bulkUpdate(this.vg_locationSelected.id, formData);
        this.snackbar.add({
            type: 'info',
            text: res.status == 200 ? "Price markup setting updated." : Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
        })
        this.fetchData()
      }else{
        res = await PriceMarkupSettingApis.bulkCreate(formData);
        this.snackbar.add({
            type: 'info',
            text: res.status == 201 ? "Price markup setting added." : Helpers.catchErrorMessage(res.response.data),
            duration: 3000,
        })
        this.fetchData()
      }
      this.va_spinner(false);
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  }

})
</script>

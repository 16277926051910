import store from '@/store/index';
import ProductApis from '@/controllers/ProductApis';
import AuthApis from '@/controllers/AuthApis';
import ApiHelper from '@/controllers/ApiHelpers';
import router from '@/router';
import Env from '@/env';

export default {
  saleSummaryByDay(param) {
    const url = `${Env.orderNestUrl}/api/orders/sales/summary/byDay?${param}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  saleSummaryByLocation(param) {
    const url = `${Env.orderNestUrl}/api/orders/sales/summary/byLocation?${param}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  saleSummaryByOrder(param) {
    const url = `${Env.orderNestUrl}/api/orders/sales/summary/byOrder?${param}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  saleSummaryByProduct(param) {
    const url = `${Env.orderNestUrl}/api/orders/sales/summary/byProduct?${param}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },

  orderDetail(orderId){
    const url = `${Env.orderNestUrl}/api/orders/order-statuses/${orderId}`;
    return AuthApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  /**
   * Fetch all products with location
   */
  inventorySnapshotReport(data) {
      const url = `/inventory-snapshots/getReportData`;
      return ProductApis.postApi(url, null, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        })
  },

  inventoryLogReport(data) {
    const url = `/inventory-adjustments/getReportData`;
    return ProductApis.postApi(url, null, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  eodSummary(date, locationId){
    const url = `${Env.orderNestUrl}/api/orders/eod/summary/byLocation?shiftDate=${date}&locationId=${locationId}`;
    return ProductApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  taxReport(params){
    const url = `${Env.orderNestUrl}/api/orders/tax/report/byLocation?${params}`;
    return ProductApis.getApi(url, null)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      })
  },
  transferReport(data){
    const url = `/inventory-transfer/getReportData`;
      return ProductApis.postApi(url, null, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        })
  }


}
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom bg-[#23242A]" }
const _hoisted_2 = { class: "flex xl:flex-row flex-col px-4 pt-4 bg-[#23242A] text-white" }
const _hoisted_3 = {
  key: 0,
  class: "xl:w-[60%] w-full custom-right transition-all duration-100 ease-linear"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_product = _resolveComponent("add-product")!
  const _component_edit_product = _resolveComponent("edit-product")!
  const _component_PrintSelectedCategories = _resolveComponent("PrintSelectedCategories")!
  const _component_MergeProducts = _resolveComponent("MergeProducts")!
  const _component_PurchaseOrderUploadPop = _resolveComponent("PurchaseOrderUploadPop")!
  const _component_qr_code_modal = _resolveComponent("qr-code-modal")!
  const _component_category_modal = _resolveComponent("category-modal")!
  const _component_select_product = _resolveComponent("select-product")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["transition-all duration-100 ease-linear custom-left mr-2", (_ctx.add || _ctx.edit || _ctx.printMenu || _ctx.mergeProducts)?'xl:w-[40%] w-full':'w-full'])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.listType.component), {
          payload: _ctx.listType.data,
          addedFromBulk: _ctx.listType.newAdded,
          addedData: _ctx.listType.newAddedData,
          resized: _ctx.add || _ctx.edit || _ctx.printMenu || _ctx.mergeProducts,
          onCloseBulkUpload: _ctx.closeBulkUpload,
          onTogglePurchaseOrderUploadPop: _ctx.togglePurchaseOrderUploadPop,
          onToggleAddEdit: _ctx.toggleAddEdit,
          onUpdatedEditItem: _ctx.updatedEditItem,
          onOpenQrCode: _ctx.openQrCode,
          onOpenMergeProducts: _ctx.openMergeProducts,
          onOpenCategorySelect: _ctx.openCategorySelect
        }, null, 40, ["payload", "addedFromBulk", "addedData", "resized", "onCloseBulkUpload", "onTogglePurchaseOrderUploadPop", "onToggleAddEdit", "onUpdatedEditItem", "onOpenQrCode", "onOpenMergeProducts", "onOpenCategorySelect"]))
      ], 2),
      (_ctx.add || _ctx.edit || _ctx.printMenu || _ctx.mergeProducts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.add)
              ? (_openBlock(), _createBlock(_component_add_product, {
                  key: 0,
                  onAdded: _ctx.addedRow,
                  item: _ctx.editItem,
                  fulfillment: _ctx.fulfillment,
                  noLocationProduct: _ctx.noLocationProduct,
                  editPageMerge: _ctx.editPageMerge,
                  onToggleAddEdit: _ctx.toggleAddEdit,
                  onClose: _ctx.closeAdd
                }, null, 8, ["onAdded", "item", "fulfillment", "noLocationProduct", "editPageMerge", "onToggleAddEdit", "onClose"]))
              : _createCommentVNode("", true),
            (_ctx.edit)
              ? (_openBlock(), _createBlock(_component_edit_product, {
                  key: 1,
                  asn: _ctx.asn,
                  editItem: _ctx.editItem,
                  fulfillment: _ctx.fulfillment,
                  onAdded: _ctx.addedRow,
                  onClose: _ctx.closeEdit,
                  onToggleAddEdit: _ctx.toggleAddEdit
                }, null, 8, ["asn", "editItem", "fulfillment", "onAdded", "onClose", "onToggleAddEdit"]))
              : _createCommentVNode("", true),
            (_ctx.printMenu)
              ? (_openBlock(), _createBlock(_component_PrintSelectedCategories, {
                  key: 2,
                  onClose: _ctx.closePrintMenu
                }, null, 8, ["onClose"]))
              : _createCommentVNode("", true),
            (_ctx.mergeProducts)
              ? (_openBlock(), _createBlock(_component_MergeProducts, {
                  key: 3,
                  data: _ctx.categoryData,
                  onClose: _ctx.closeMergeProduct
                }, null, 8, ["data", "onClose"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.purchaseOrderUploadPop)
      ? (_openBlock(), _createBlock(_component_PurchaseOrderUploadPop, {
          key: 0,
          showTendyUpload: _ctx.showTendyUpload,
          onClose: _ctx.closePurchaseOrderUpload,
          onUploadedPayload: _ctx.uploadedPayload
        }, null, 8, ["showTendyUpload", "onClose", "onUploadedPayload"]))
      : _createCommentVNode("", true),
    (_ctx.qrcode.modal)
      ? (_openBlock(), _createBlock(_component_qr_code_modal, {
          key: 1,
          closeCallBack: _ctx.qrCloseCallBack,
          products: _ctx.categoryData
        }, null, 8, ["closeCallBack", "products"]))
      : _createCommentVNode("", true),
    (_ctx.category.modal)
      ? (_openBlock(), _createBlock(_component_category_modal, {
          key: 2,
          data: _ctx.categoryData,
          onClose: _ctx.closeCategory
        }, null, 8, ["data", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.exportProductCatalogue)
      ? (_openBlock(), _createBlock(_component_select_product, {
          key: 3,
          exportProductSelected: _ctx.exportProductSelected,
          onClose: _ctx.closeExportCatalogue
        }, null, 8, ["exportProductSelected", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}